<template>
  <div>
    <div class="main-title">我购买的产品</div>
    <el-form
      :inline="true"
      ref="searchForm"
      :model="searchForm"
      class="searchForm"
      @keyup.enter.native="refreshList()"
      @submit.native.prevent
    >
      <!-- 搜索框-->
      <el-form-item prop="productNo">
        <el-input
          v-model="searchForm.productNo"
          placeholder="产品序列号"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item prop="user.email">
        <el-input
          v-model="searchForm.user.email"
          placeholder="邮箱"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="queryUser.nickname">
        <el-input
          v-model="searchForm.queryUser.nickname"
          placeholder="昵称"
          clearable
        ></el-input>
      </el-form-item> -->
      <el-form-item>
        <el-button @click="refreshList()" icon="el-icon-search">查询</el-button>
        <el-button @click="resetSearch()" icon="el-icon-refresh-right"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <div v-if="tablePage.total === 0" class="list">
      <el-empty description="暂无数据">
        <template #image>
          <img src="@/assets/image/empty.svg" alt="no-data" />
        </template>
      </el-empty>
    </div>

    <div v-else>
      <div v-loading.fullscreen.lock="loading">
        <div class="list">
          <el-table
            :loading="loading"
            size="small"
            ref="blogPostTable"
            @sort-change="sortChangeHandle"
            :data="dataList"
          >
            <el-table-column
              sort-by="product.name"
              prop="title"
              sortable="custom"
              label="产品"
            >
              <template slot-scope="scope">
                {{ scope.row.product && scope.row.product.name }}
              </template>
            </el-table-column>

            <el-table-column
              prop="productVersion.name"
              sortable="custom"
              label="版本"
            >
              <template slot-scope="scope">
                {{ scope.row.productVersion && scope.row.productVersion.name }}
              </template>
            </el-table-column>
            <el-table-column
              prop="productNo"
              sortable="custom"
              label="产品序列号"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="user.email"
              sortable="custom"
              label="用户邮箱"
            >
            </el-table-column>
            <el-table-column
              prop="user.nickname"
              sortable="custom"
              label="用户昵称"
            >
            </el-table-column> -->
            <el-table-column
              prop="gencodeMax"
              sortable="custom"
              width="170px"
              label="代码生成器允许次数"
            >
            </el-table-column>
            <el-table-column
              prop="gencodeNum"
              sortable="custom"
              label="已使用次数"
            >
            </el-table-column>
            <el-table-column
              prop="endDate"
              sortable="custom"
              label="授权截止日期"
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.endDate"> 永久授权 </span>
                <span v-else-if="scope.row.endDate > '2100-01-01'">
                  永久授权
                </span>
                <span v-else>
                  {{ scope.row.endDate }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="price" sortable="custom" label="授权价格">
            </el-table-column>

            <el-table-column
              prop="createDate"
              sort-by="a.create_date"
              sortable="custom"
              label="购买时间"
            >
            </el-table-column>

            <el-table-column width="200" align="left" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  icon="el-icon-download"
                  size="small"
                  @click="download(scope.row)"
                  >下载</el-button
                >
                <el-button
                  v-if="scope.row.authRsa"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  @click="gencode(scope.row)"
                  >注册代码生成器</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="text-align: right; margin: 20px 10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="tablePage.currentPage"
            :page-sizes="[5, 10, 20, 100]"
            :page-size="tablePage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tablePage.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userProductService from "@/service/api/bbs/auth/userProductService.js";
// import { toLine } from "@/utils/utils";
export default {
  data() {
    return {
      searchForm: {
        productNo: "",
        user: {
          email: "",
        },
        queryUser: {
          nickname: "",
        },
      },
      dataList: [],
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        orders: [{ column: "a.create_date", asc: false }],
      },
      loading: false,
    };
  },
  mounted() {
    this.refreshList();
  },
  methods: {
    // 获取数据列表
    refreshList() {
      this.loading = true;
      userProductService
        .mine({
          current: this.tablePage.currentPage,
          size: this.tablePage.pageSize,
          orders: this.tablePage.orders,
          ...this.searchForm,
        })
        .then((data) => {
          this.dataList = data.records;
          this.tablePage.total = data.total;
          this.loading = false;
        });
    },
    sortChangeHandle(obj) {
      this.tablePage.orders = [];
      this.tablePage.orders.push({
        column: obj.column.sortBy || obj.prop,
        asc: obj.order === "ascending",
      });
      this.refreshList();
    },
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.refreshList();
    },
    handleCurrentChange(val) {
      this.tablePage.currentPage = val;
      this.refreshList();
    },
    gencode(row) {
      this.$router.push(
        "/userCenter/myGencode?id=" + row.id + "&productId=" + row.product.id
      );
    },
    // 修改
    download(row) {
      console.log(row);
      const loading = this.$loading({
        text: "建议使用谷歌浏览器下载。代码文件打包中，请稍等...",
      });
      userProductService.download(row.id).then((url) => {
        if (url == "downloadPackage") {
          userProductService.downloadPackage(row.id).then((data) => {
            var blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            }); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
            var downloadElement = document.createElement("a");
            var href = window.URL.createObjectURL(blob); // 创建下载的链接
            downloadElement.href = href;
            downloadElement.download = `${row.user.email}-${row.product.name}-${row.product.version}.zip`; // 下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); // 点击下载
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
            loading.close();
            // eslint-disable-next-line handle-callback-err
          });
        } else {
          // let href =
          //   url.replace("http: ", "http:") +
          //   `&attname=${row.user.email}-${row.product.name}-${row.product.version}`;
          this.downloadZip(
            url.replace("http:", "https:").replace("http: ", "https:"),
            `${row.user.email}-${row.product.name}-${row.product.version}.zip`
          );

          loading.close();
        }
      });
    },
    downloadZip(url, fileName) {
      const x = new window.XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
    resetSearch() {
      this.$refs.searchForm.resetFields();
      this.refreshList();
    },
  },
};
</script>
<style lang="less">
.el-empty__image {
  width: 300px;
}
.main-title {
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  text-align: left;
  color: #111111;
  display: block;
  letter-spacing: 0;
  font-weight: 600;
  margin: 0px 0px 30px 0px;
}

.searchForm {
  text-align: right;
  padding: 20px 20px 15px 70px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td {
  vertical-align: middle;
}

a {
  outline: none;
  color: #118dfd;
  text-decoration: none;
}

a:focus {
  outline: none;
}

input:focus,
select:focus,
textarea:focus {
  outline: -webkit-focus-ring-color auto 0;
}

.mainColorBtn {
  min-width: 120px;
  text-align: center;
  background-color: #118dfd !important;
  border-color: #118dfd !important;
  color: #fff !important;
}

.mainBorderColorBtn {
  background-color: #fff !important;
  border-color: #118dfd !important;
  color: #118dfd !important;
}

.footerBtn {
  min-width: 120px;
  height: 40px;
  margin-bottom: 20px;
}

.linkView {
  color: #3873ec;
  cursor: pointer;
  font-weight: 600;
}

.fcc {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 按钮 */
.el-button--default,
.el-button--default:focus {
  border: 1px solid #118dfd;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  color: #118dfd;
  font-weight: 400;
  background: #ffffff;
}
.el-button--default:hover {
  background: #eaf3ffe0;
  border: 1px solid #118dfd;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  color: #118dfd;
  font-weight: 400;
}

.el-button--primary,
.el-button--primary:focus {
  background: #118dfd;
  border: 1px solid #118dfd;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.el-button--primary:hover {
  background: #118dfd;
  color: #ffffff;
  border: 1px solid #118dfd;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  background-color: #118dfd;
  border-color: #118dfd;
}

/* 表格 */
.el-table th {
  background: #f5f7f9 !important;
}

.el-table th .cell {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #111111;
  letter-spacing: 0;
  font-weight: 550;
}
.el-form-item__label {
  font-family: PingFangSC-Regular;
  color: #111111;
  letter-spacing: 0;
  font-weight: 400;
}
.el-date-table td,
.el-table .cell {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #111111;
  letter-spacing: 0;
  font-weight: 400;
}
</style>
