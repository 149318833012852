import request from "@/utils/httpRequest";

export default {
  save: function (inputForm) {
    return request({
      url: "/front/bbs/auth/userProduct/save",
      method: "post",
      data: inputForm,
    });
  },

  delete: function (ids) {
    return request({
      url: "/front/bbs/auth/userProduct/delete",
      method: "delete",
      params: { ids: ids },
    });
  },

  queryById: function (id) {
    return request({
      url: "/front/bbs/auth/userProduct/queryById",
      method: "get",
      params: { id: id },
    });
  },

  mine: function (params) {
    return request({
      url: "/front/bbs/auth/userProduct/mine/list",
      method: "get",
      params: params,
    });
  },
  download: function (id) {
    return request({
      url: "/front/bbs/auth/userProduct/download",
      method: "get",
      params: { id: id },
    });
  },
  downloadPackage: function (id) {
    return request({
      url: "/front/bbs/auth/userProduct/downloadPackage",
      method: "get",
      responseType: "blob",
      params: { id: id },
    });
  },
  list: function (params) {
    return request({
      url: "/front/bbs/auth/userProduct/list",
      method: "get",
      params: params,
    });
  },
};
