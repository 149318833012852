<template>
  <div class="gencode">
    <div class="main-title">注册代码生成器</div>
    <el-form
      :inline="true"
      ref="searchForm"
      :model="searchForm"
      class="searchForm"
      @keyup.enter.native="gencode()"
      @submit.native.prevent
    >
      <!-- 搜索框-->
      <el-form-item prop="productNo" label="机器码">
        <el-input
          style="width: 500px"
          v-model="searchForm.mac"
          placeholder="请输入机器码"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="gencode()" icon="el-icon-edit"
          >生成license</el-button
        >
        <el-button @click="search()" icon="el-icon-search">查询</el-button>
      </el-form-item>
    </el-form>

    <div v-if="tablePage.total === 0" class="list">
      <el-empty description="暂无数据">
        <template #image>
          <img src="@/assets/image/empty.svg" alt="no-data" />
        </template>
      </el-empty>
    </div>

    <div v-else>
      <div v-loading.fullscreen.lock="loading">
        <div class="list">
          <el-table
            :loading="loading"
            size="small"
            ref="blogPostTable"
            @sort-change="sortChangeHandle"
            :data="dataList"
          >
            <el-table-column
              sort-by="product.name"
              prop="title"
              sortable="custom"
              label="产品"
            >
              <template slot-scope="scope">
                {{ scope.row.product && scope.row.product.name }}
              </template>
            </el-table-column>

            <el-table-column
              prop="productNo"
              sortable="custom"
              label="产品序列号"
            >
            </el-table-column>
            <el-table-column prop="mac" sortable="custom" label="机器码">
            </el-table-column>
            <el-table-column prop="license" sortable="custom" label="授权码">
            </el-table-column>
            <el-table-column
              prop="createDate"
              sort-by="a.create_date"
              sortable="custom"
              label="创建日期"
            >
            </el-table-column>
            <el-table-column
              prop="endDate"
              sortable="custom"
              label="授权截止日期"
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.endDate"> 永久授权 </span>
                <span v-else-if="scope.row.endDate > '2100-01-01'">
                  永久授权
                </span>
                <span v-else>
                  {{ scope.row.endDate }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="left" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                  @click="del(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="text-align: right; margin: 20px 10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="tablePage.currentPage"
            :page-sizes="[5, 10, 20, 100]"
            :page-size="tablePage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tablePage.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userGencodeService from "@/service/api/bbs/auth/userGencodeService.js";
// import { toLine } from "@/utils/utils";
export default {
  data() {
    return {
      searchForm: {
        product: {
          id: "",
        },
        mac: "",
      },
      dataList: [],
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        orders: [{ column: "a.create_date", asc: false }],
      },
      loading: false,
    };
  },
  mounted() {
    this.refreshList();
  },
  watch: {
    "$route.query.productId": {
      handler(val) {
        this.searchForm.product.id = val;
      },
      immediate: true,
    },
  },
  methods: {
    // 获取数据列表
    refreshList() {
      this.loading = true;
      userGencodeService
        .mine({
          current: this.tablePage.currentPage,
          size: this.tablePage.pageSize,
          orders: this.tablePage.orders,
          ...this.searchForm,
        })
        .then((data) => {
          this.dataList = data.records;
          this.tablePage.total = data.total;
          this.loading = false;
        });
    },
    del(id) {
      this.$confirm(`确定删除所选项吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        userGencodeService.delete(id).then(() => {
          this.refreshList();
          this.loading = false;
        });
      });
    },
    sortChangeHandle(obj) {
      this.tablePage.orders = [];
      this.tablePage.orders.push({
        column: obj.column.sortBy || obj.prop,
        asc: obj.order === "ascending",
      });
      this.refreshList();
    },
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.refreshList();
    },
    handleCurrentChange(val) {
      this.tablePage.currentPage = val;
      this.refreshList();
    },
    // 修改
    gencode() {
      if (!this.searchForm.mac) {
        this.$message.error("机器码不能为空!");
        return;
      }
      userGencodeService
        .addLicense({ id: this.$route.query.id, mac: this.searchForm.mac })
        .then((data) => {
          this.$message.success(data);
          this.refreshList();
        });
    },

    search() {
      this.refreshList();
    },
  },
};
</script>
<style lang="less">
.gencode {
  .el-empty__image {
    width: 300px;
  }
  .main-title {
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    text-align: left;
    color: #111111;
    display: block;
    letter-spacing: 0;
    font-weight: 600;
    margin: 0px 0px 30px 0px;
  }

  .searchForm {
    text-align: center;
    padding: 20px 20px 15px 70px;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  th,
  td {
    vertical-align: middle;
  }

  a {
    outline: none;
    color: #118dfd;
    text-decoration: none;
  }

  a:focus {
    outline: none;
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: -webkit-focus-ring-color auto 0;
  }

  .mainColorBtn {
    min-width: 120px;
    text-align: center;
    background-color: #118dfd !important;
    border-color: #118dfd !important;
    color: #fff !important;
  }

  .mainBorderColorBtn {
    background-color: #fff !important;
    border-color: #118dfd !important;
    color: #118dfd !important;
  }

  .footerBtn {
    min-width: 120px;
    height: 40px;
    margin-bottom: 20px;
  }

  .linkView {
    color: #3873ec;
    cursor: pointer;
    font-weight: 600;
  }

  .fcc {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* 按钮 */
  .el-button--default,
  .el-button--default:focus {
    border: 1px solid #118dfd;
    border-radius: 4px;
    font-family: PingFangSC-Regular;
    color: #118dfd;
    font-weight: 400;
    background: #ffffff;
  }
  .el-button--default:hover {
    background: #eaf3ffe0;
    border: 1px solid #118dfd;
    border-radius: 4px;
    font-family: PingFangSC-Regular;
    color: #118dfd;
    font-weight: 400;
  }

  .el-button--primary,
  .el-button--primary:focus {
    background: #118dfd;
    border: 1px solid #118dfd;
    border-radius: 4px;
    font-family: PingFangSC-Regular;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 400;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .el-button--primary:hover {
    background: #118dfd;
    color: #ffffff;
    border: 1px solid #118dfd;
  }

  .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover {
    background-color: #118dfd;
    border-color: #118dfd;
  }

  /* 表格 */
  .el-table th {
    background: #f5f7f9 !important;
  }

  .el-table th .cell {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #111111;
    letter-spacing: 0;
    font-weight: 550;
  }
  .el-form-item__label {
    font-family: PingFangSC-Regular;
    color: #111111;
    letter-spacing: 0;
    font-weight: 400;
  }
  .el-date-table td,
  .el-table .cell {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #111111;
    letter-spacing: 0;
    font-weight: 400;
  }
}
</style>
